<template>
  <div class="event-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_event"
        :hoverInfo="translations.labels.button_add_event_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList :is-ready="isPageReady" :headers="headers" :items="model" />
  </div>
</template>

<script lang="js">
import Vue from "vue";
import Component from "vue-class-component";
import { toastService } from "@/services/toastService.js";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
   components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList
  },
  inject: {
    eventService: "eventService"
  }
})

export default class EventList extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  async init () {
    try {
      this.model = await this.eventService.list();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

    toastSuccessLabel (isEnabled) {
      isEnabled ?
      toastService.success(this.translations.success.event_disable)
      : toastService.success(this.translations.success.event_enable);
    }

    async swapEnabled(idEvent, isEnabled) {
      const status = isEnabled ? "disable" : "enable";
      this.isPageReady = false;

      try {
            await this.eventService.setEnabling(idEvent, status);
            this.model = await this.eventService.list();
            this.toastSuccessLabel(isEnabled);
            this.isPageReady = true;
      } catch (error) {
            this.isPageReady = true;
            toastService.error(this.translations.errors.somethingWrong);
      }
    }

    newItem() {
      this.$router.push("/event/new");
    }

    get translations() {
      return this.$store.state.translationsStore.currentLang;
    }

    get headers() {
        return [
          {
            text: this.translations.labels.event_table_code,
            value: "code"
          },
          {
            text: this.translations.labels.event_table_publishDate,
            value: "publishDate",
            type:"date"
          },
          {
            text: this.translations.labels.event_table_startDate,
            value: "startDate",
            type:"datetime"
          },
          {
            text: this.translations.labels.event_table_endDate,
            value: "endDate",
            type:"datetime"
          },
          {
            text: this.translations.labels.table_detail,
            value: "detail",
            type:"link",
            formatValue:(item)=>{return `/event/detail/${item.id}`;},
            icon:"mdi-feature-search-outline"
          },
          {
            text: this.translations.labels.prefilled_swagbag,
            value: "file",
            type: "link",
            icon: "mdi-shopping",
            buttonType: 2,
            formatValue: (item) => {
              return `/swagbag/list/${item.id}`;
            },
          },
          {
            text: this.translations.labels.table_edit,
            value: "edit",
            type:"link",
            formatValue:(item)=>{return `/event/edit/${item.id}`;},
            icon:"mdi-pencil",
            buttonType:2
          },
          {
            text: this.translations.labels.table_config,
            value: "config",
            type:"link",
            formatValue:(item)=>{return `/event/${item.id}/config`;},
            icon:"mdi-cog",
            buttonType:2
          },
          {
            text: this.translations.labels.table_enable,
            value: "enable",
            type: "switch",
            itemValue:(item)=>{return item.isEnabled;},
            onAction: (item) => {
              this.swapEnabled(item.id, item.isEnabled);
            },
          }
        ];
      }

  mounted () {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.events_list
    );
  }
}

</script>
<style lang="scss" scoped>
.event-list {
  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
